import { AxiosRequestConfig } from "axios";
import { getAuthTokenFromCookies } from "../utils/auth";
import { axiosInstance } from "./axiosInstance";

interface getRoomListParams {
  nowPage: number;
  limit: number;
}

export const getRoomList = async (params: getRoomListParams) => {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${getAuthTokenFromCookies("user_token")}`,
    },
  };
  const response = await axiosInstance(config).get(
    `/rooms?page=${params.nowPage}&limit=${params.limit}&order=id_desc`
  );
  return response.data;
};

export default getRoomList;
