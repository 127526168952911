import { AxiosRequestConfig } from "axios";
import { getAuthTokenFromCookies } from "../utils/auth";
import { axiosInstance } from "./axiosInstance";

export interface EnterRoomParams {
  _id: string;
  inout: boolean;
}

export const enterRoom = async (params: EnterRoomParams) => {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${getAuthTokenFromCookies("user_token")}`,
    },
    data: {
      _id: params._id,
      inout: params.inout,
    },
  };
  const response = await axiosInstance(config).post(
    `/rooms/${params._id}`,
    config.data
  );
  console.log(response.data);
  return response.data;
};

export default enterRoom;
