import { AxiosRequestConfig } from "axios";
import { axiosInstance } from "./axiosInstance";

export interface SignUpParams {
  email: string;
  password: string;
  inGameUID: string;
  nickName: string;
}

export const signUp = async (params: SignUpParams) => {
  const config: AxiosRequestConfig = {
    data: {
      email: params.email,
      password: params.password,
      inGameUID: params.inGameUID,
      nickName: params.nickName,
    },
  };
  const response = await axiosInstance(config).post(`/signUp`, config.data);
  return response.data;
};

export default signUp;
