import {
  call,
  put,
  takeLatest,
  all,
  delay,
  takeEvery,
} from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { actions, GetRoomListState } from "./slice";
import getRoomList from "../../api/getRoomList";

function* getRoomListSaga(
  action: PayloadAction<Pick<GetRoomListState, "nowPage" | "limit">>
) {
  yield delay(100);
  const config: Pick<GetRoomListState, "nowPage" | "limit"> = {
    nowPage: action.payload.nowPage,
    limit: action.payload.limit,
  };

  try {
    const result: GetRoomListState = yield call(() => getRoomList(config));
    yield put(actions.getRoomListFullfilled(result));
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err;
    }
    yield put(actions.getRoomListRejected());
  }
}

function* watchGetRoomList() {
  yield takeLatest(actions.getRoomListPending.type, getRoomListSaga);
  // yield takeEvery([/**추가/삭제/수정액션 올곳 */], getCraftshopListSaga)
}

export default function* rootSaga() {
  yield all([watchGetRoomList()]);
}
