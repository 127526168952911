import { useEffect } from "react";
import enterRoom from "../api/enterRoom";

export default function RoomElement() {
  useEffect(() => {
    enterRoom({
      _id: globalThis.location.pathname.split("/")[2],
      inout: true,
    });
    return () => {};
  }, []);
  return (
    <>
      <div>입장한 방</div>
    </>
  );
}
