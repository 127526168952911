import { createStyles, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useNavigate } from "react-router";
import signUp, { SignUpParams } from "../api/signUp";

const useStyles = makeStyles(() =>
  createStyles({
    signUpContainer: {
      marginTop: "100px",
      border: "1px solid black",
      margin: "0 auto 0 auto",
      maxWidth: "375px",
      minHeight: "200px",
      paddingTop: "20px",

      display: "grid",
      gridTemplateRows: "repeat(8, 1fr)",
      gridAutoColumns: "1fr 2fr",
      gridGap: "10px",
      gridTemplateAreas: `
      "header header"
      "headText headText"
      "email emailInput"
      "password passwordInput"
      "passwordConfirm passwordConfirmInput"
      "inGameUID inGameUIDInput"
      "nickName nickNameInput"
      "signUp signUp"
      `,
    },
    header: {
      gridArea: "header",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      fontWeight: "bold",
    },
    headText: {
      gridArea: "headText",
      alignItems: "center",
      marginLeft: "10px",
    },
    emailText: {
      gridArea: "email",
      display: "flex",
      alignItems: "center",
      marginLeft: "10px",
    },
    emailInput: {
      gridArea: "emailInput",
      marginRight: "20px",
    },
    passwordText: {
      gridArea: "password",
      display: "flex",
      alignItems: "center",
      marginLeft: "10px",
    },
    passwordInput: {
      gridArea: "passwordInput",
      marginRight: "20px",
    },
    passwordConfirmText: {
      gridArea: "passwordConfirm",
      display: "flex",
      alignItems: "center",
      marginLeft: "10px",
    },
    passwordConfirmInput: {
      gridArea: "passwordConfirmInput",
      marginRight: "20px",
    },
    inGameUIDText: {
      gridArea: "inGameUID",
      display: "flex",
      alignItems: "center",
      marginLeft: "10px",
    },
    inGameUIDInput: {
      gridArea: "inGameUIDInput",
      marginRight: "20px",
    },
    nickNameText: {
      gridArea: "nickName",
      display: "flex",
      alignItems: "center",
      marginLeft: "10px",
    },
    nickNameInput: {
      gridArea: "nickNameInput",
      marginRight: "20px",
    },
    signUpBtn: {
      gridArea: "signUp",
    },
  })
);

export default function SignUp() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [userValue, setUserValue] = useState({
    email: "",
    password: "",
    passwordConfirm: "",
    inGameUID: "",
    nickName: "",
  });

  const [passwordExist, setPasswordExist] = useState(false);

  const { email, password, passwordConfirm, inGameUID, nickName } = userValue;

  const handleChangeEmail: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { value, name } = e.target;
    setUserValue({
      ...userValue,
      [name]: value,
    });
  };
  const handleChangePassword: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { value, name } = e.target;
    setUserValue({
      ...userValue,
      [name]: value,
    });
  };
  const handleChangePasswordConfirm: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { value, name } = e.target;
    setUserValue({
      ...userValue,
      [name]: value,
    });
    if (value !== userValue.password) {
      const confirmTextNode = document.getElementsByClassName(
        classes.passwordConfirmText
      )[0];
      confirmTextNode.innerHTML = "위와 다릅니다!";
      setPasswordExist(false);
    } else {
      const confirmTextNode = document.getElementsByClassName(
        classes.passwordConfirmText
      )[0];
      confirmTextNode.innerHTML = "일치합니다";
      setPasswordExist(true);
    }
  };
  const handleChangeInGameUID: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { value, name } = e.target;
    setUserValue({
      ...userValue,
      [name]: value,
    });
  };
  const handleChangeNickName: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { value, name } = e.target;
    setUserValue({
      ...userValue,
      [name]: value,
    });
  };

  return (
    <>
      <form
        className={classes.signUpContainer}
        onSubmit={async (e) => {
          e.preventDefault();
          const result = await signUp({
            email: userValue.email,
            password: userValue.password,
            inGameUID: userValue.inGameUID,
            nickName: userValue.nickName,
          });
          if (result === "rejected") {
            alert("이미 가입된 회원이거나 잘못된 정보를 입력했습니다.");
          } else {
            alert("가입 완료되었습니다. 로그인창으로 이동합니다.");
            navigate("/signIn");
          }
        }}
      >
        <div className={classes.header}>회원가입</div>
        <div className={classes.headText}>
          가입 후 마이페이지에서 구매곡을 등록해주세요(미구현)
        </div>

        <div className={classes.emailText}>이메일주소</div>
        <input
          className={classes.emailInput}
          type="email"
          name="email"
          placeholder="로그인, 임시비밀번호 발급용도"
          value={userValue.email}
          onChange={handleChangeEmail}
        />

        <div className={classes.passwordText}>비밀번호</div>
        <input
          className={classes.passwordInput}
          type="password"
          name="password"
          placeholder="비밀번호(8자이상)"
          value={userValue.password}
          onChange={handleChangePassword}
        />

        <div className={classes.passwordConfirmText}>비밀번호 확인</div>
        <input
          className={classes.passwordConfirmInput}
          type="password"
          name="passwordConfirm"
          placeholder="비밀번호확인"
          value={userValue.passwordConfirm}
          onChange={handleChangePasswordConfirm}
        />

        <div className={classes.inGameUIDText}>아르케아 UID</div>
        <input
          className={classes.inGameUIDInput}
          type="text"
          name="inGameUID"
          placeholder="ex) 123 456 789 => 123456789"
          value={userValue.inGameUID}
          onChange={handleChangeInGameUID}
        />

        <div className={classes.nickNameText}>닉네임</div>
        <input
          className={classes.nickNameInput}
          type="text"
          name="nickName"
          placeholder="플레이룸에서 보여지는 이름입니다"
          value={userValue.nickName}
          onChange={handleChangeNickName}
        />

        <button
          className={classes.signUpBtn}
          disabled={!passwordExist}
          onClick={() => {
            navigate("/signUp");
          }}
        >
          회원가입
        </button>
      </form>
    </>
  );
}
