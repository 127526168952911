import { AxiosRequestConfig } from "axios";
import { getAuthTokenFromCookies } from "../utils/auth";
import { axiosInstance } from "./axiosInstance";

export interface CreateRoomParams {
  title: string;
  comment: string;
  code: string;
}

export const createRoom = async (params: CreateRoomParams) => {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${getAuthTokenFromCookies("user_token")}`,
    },
    data: {
      title: params.title,
      comment: params.comment,
      code: params.code,
    },
  };
  const response = await axiosInstance(config).post(`/rooms`, config.data);
  console.log(response.data);
  return response.data;
};

export default createRoom;
