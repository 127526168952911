import { AxiosRequestConfig } from "axios";
import { axiosInstance } from "./axiosInstance";

export interface SignInParams {
  email: string;
  password: string;
}

export const signIn = async (params: SignInParams) => {
  const config: AxiosRequestConfig = {
    data: {
      email: params.email,
      password: params.password,
    },
  };
  const response = await axiosInstance(config).post(`/signIn`, config.data);
  return response.data;
};

export default signIn;
