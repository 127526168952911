import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Room } from "../../components/RoomList";

export interface GetRoomListState {
  rooms: Room[];
  nowPage: number;
  limit: number;
  maxPage: number;
  isLoadingRoomList: boolean;
}

const initialState: GetRoomListState = {
  rooms: [],
  nowPage: 1,
  limit: 10,
  maxPage: 1,
  isLoadingRoomList: false,
};

export const getRoomListSlice = createSlice({
  name: "getRoomList",
  initialState,
  reducers: {
    getRoomListPending: (
      state,
      action: PayloadAction<Pick<GetRoomListState, "nowPage" | "limit">>
    ) => {
      state.isLoadingRoomList = true;
    },
    getRoomListFullfilled: (state, action: PayloadAction<GetRoomListState>) => {
      state.rooms = action.payload.rooms;
      state.nowPage = action.payload.nowPage;
      state.maxPage = action.payload.maxPage;
      state.isLoadingRoomList = false;
    },
    getRoomListRejected: (state) => {
      state.isLoadingRoomList = false;
    },
  },
});

export const { actions } = getRoomListSlice;
export default getRoomListSlice.reducer;
