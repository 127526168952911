import {
  createStyles,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { useNavigate } from "react-router";
import createRoom, { CreateRoomParams } from "../api/createRoom";

const useStyles = makeStyles(() =>
  createStyles({
    createContainer: {
      margin: "20px 10px 20px 10px",
    },
    inputForm: {
      display: "grid",
      gridTemplateRows: "1fr 1fr 3fr 1fr",
      gridTemplateColumns: "70px 6fr",
      gridGap: "10px",
      gridTemplateAreas: `
      "title titleInput"
      "code codeInput"
      "comment comment"
      ". submit"`,
    },
    titleText: {
      gridArea: "title",
      minHeight: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    titleInput: {
      gridArea: "titleInput",
    },
    codeText: {
      gridArea: "code",
      minHeight: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    codeInput: {
      gridArea: "codeInput",
    },
    commentInput: {
      gridArea: "comment",
    },
    submitBtn: {
      gridArea: "submit",
      width: "100px",
      marginLeft: "auto",
    },
  })
);
export default function CreateRoom() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [roomValue, setRoomValue] = useState({
    title: "",
    code: "",
    comment: "",
  });
  const { title, code, comment } = roomValue;

  const handleChangeTitle: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { value, name } = e.target;
    setRoomValue({
      ...roomValue,
      [name]: value,
    });
  };

  const handleChangeCode: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { value, name } = e.target;
    setRoomValue({
      ...roomValue,
      [name]: value,
    });
  };

  const handleChangeComment: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { value, name } = e.target;
    setRoomValue({
      ...roomValue,
      [name]: value,
    });
  };

  return (
    <>
      <div className={classes.createContainer}>
        <Typography variant="h5" gutterBottom>
          방 개설하기
        </Typography>
        <Typography variant="subtitle1" gutterBottom color="textSecondary">
          모두가 방을 나가거나, 방을 만든지 1시간이되면 삭제됩니다.
        </Typography>
        <form className={classes.inputForm}>
          <Typography
            className={classes.titleText}
            variant="subtitle1"
            gutterBottom
            color="textSecondary"
          >
            제목
          </Typography>
          <TextField
            className={classes.titleInput}
            id="outlined-basic"
            variant="outlined"
            size="small"
            name="title"
            onChange={handleChangeTitle}
          />
          <Typography
            className={classes.codeText}
            variant="subtitle1"
            gutterBottom
            color="textSecondary"
          >
            입장코드
          </Typography>
          <TextField
            className={classes.codeInput}
            id="outlined-basic"
            variant="outlined"
            size="small"
            placeholder="입장코드는 방 입장 후 보여지게됩니다."
            name="code"
            onChange={handleChangeCode}
          />
          <TextField
            className={classes.commentInput}
            id="outlined-multiline-static"
            multiline
            rows={4}
            placeholder={`간단한 규칙을 작성해주세요\nex) RoundRobin`}
            variant="outlined"
            name="comment"
            onChange={handleChangeComment}
          />
          <button
            className={classes.submitBtn}
            onClick={() => {
              if (roomValue.code === "" || roomValue.title === "") {
                alert("방 제목과 입장코드를 입력하세요");
                return;
              }
              createRoom(roomValue);
              navigate("/roomList");
            }}
          >
            개설하기
          </button>
        </form>
      </div>
    </>
  );
}
