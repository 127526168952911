import { createStyles, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useNavigate } from "react-router";
import signIn from "../api/signIn";
import { saveAuthToken } from "../utils/auth";

const useStyles = makeStyles(() =>
  createStyles({
    signInContainer: {
      marginTop: "100px",
      border: "1px solid black",
      margin: "0 auto 0 auto",
      maxWidth: "300px",
      minHeight: "200px",

      display: "grid",
      gridTemplateRows: "1fr 1fr 1fr",
      gridAutoColumns: "1fr 1fr",
      gridTemplateAreas: `
      "header header"
      "email email"
      "password password"
      "signIn signUp"
      `,
    },
    signInHeader: {
      gridArea: "header",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      fontWeight: "bold",
    },
    signInEmail: {
      gridArea: "email",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    signInPassword: {
      gridArea: "password",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    signInBtn: {
      gridArea: "signIn",
      height: "30px",
    },
    signUpBtn: {
      gridArea: "signUp",
      height: "30px",
    },
  })
);

interface SignInParams {
  email: string;
  password: string;
}

export default function SignIn() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleChangeEmail: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleChangePassword: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { value } = e.target;
    setPassword(value);
  };

  return (
    <>
      <form
        className={classes.signInContainer}
        onSubmit={async (e) => {
          e.preventDefault();
          const { token } = await signIn({
            email: email,
            password: password,
          });
          if (!token) {
            alert("이메일 또는 비밀번호가 틀렸습니다.");
          }
          saveAuthToken(token);
          navigate("/roomList");
        }}
      >
        <div className={classes.signInHeader}>로그인</div>
        <input
          className={classes.signInEmail}
          type="email"
          placeholder="Email"
          value={email}
          onChange={handleChangeEmail}
        ></input>
        <input
          className={classes.signInPassword}
          type="password"
          placeholder="Password"
          value={password}
          onChange={handleChangePassword}
        ></input>
        <button
          className={classes.signInBtn}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          로그인
        </button>
        <button
          className={classes.signUpBtn}
          onClick={() => {
            navigate("/signUp");
          }}
        >
          회원가입
        </button>
      </form>
    </>
  );
}
