import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import { Provider } from "react-redux";
import { history, store } from "./modules/store";
import { ConnectedRouter } from "connected-react-router";
// import { SnackbarProvider } from "notistack";
// import { SnackbarUtilsConfigurator } from "./util/snackBarUitls";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
