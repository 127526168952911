import { all } from "@redux-saga/core/effects";

import roomList from "../store/roomList/slice";
import roomListSaga from "../store/roomList/saga";

export const reducers = {
  roomList,
};

export function* rootSaga() {
  yield all([roomListSaga()]);
}
