import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useAppSelector } from "../modules/hook";
import { actions as getRoomsActions } from "../store/roomList/slice";
import { getAuthTokenFromCookies } from "../utils/auth";
import PeopleNavi from "./PeopleNavi";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    roomList: {
      display: "flex",
      flexWrap: "wrap",
    },
    room: {
      flex: "1 1 40%",
      ["@media (max-width: 400px)"]: {
        // eslint-disable-line no-useless-computed-key
        flex: "1 1 60%",
      },
      border: "black solid 0.5px",
      marginBottom: "10px",
      display: "grid",
      gridAutoColumns: "1fr 1fr",
      gridAutoRows: "1fr 1fr 1fr 3fr 1fr",
      gridTemplateAreas: `
      "title title"
      "createTime createTime"
      "host nowPeople"
      "comment comment"
      "enterBtn enterBtn"
      `,
    },
    roomTitle: {
      gridArea: "title",
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: "10px",
      background: "#878787",
      borderBottom: "black solid 0.5px",
    },
    roomCreateTime: {
      gridArea: "createTime",
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: "10px",
      background: "#878787",
      borderBottom: "black solid 0.5px",
    },
    roomHost: {
      gridArea: "host",
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: "10px",
      borderBottom: "black solid 0.5px",
      borderRight: "black solid 0.5px",
    },
    roomNowPeople: {
      gridArea: "nowPeople",
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: "10px",
      borderBottom: "black solid 0.5px",
    },
    roomComment: {
      gridArea: "comment",
      display: "flex",
      justifyContent: "flex-start",
      padding: "10px",
      borderBottom: "black solid 0.5px",
    },
    roomEnterBtn: {
      gridArea: "enterBtn",
    },
    pagenationNavi: {
      display: "flex",
      justifyContent: "center",
    },
    mainText: {
      margin: "10px 20px 20px 20px",
    },
  })
);

export interface Room {
  _id: string;
  host: string;
  title: string;
  comment: string;
  _nowMembers: Array<string>;
  code: string;
  createdAt: string;
}

export default function RoomList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectPage, setNowPage] = useState(1);
  const paginationNavigationHandler = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setNowPage(value);
    dispatch(
      getRoomsActions.getRoomListPending({
        nowPage: value,
        limit: 8,
      })
    );
  };

  const createTime = (createdAt: string) => {
    const yyyymmddArray = createdAt.split("T")[0].split("-");
    const hhmmssArray = createdAt.split("T")[1].split(".")[0].split(":");
    return `${yyyymmddArray[1]}월${yyyymmddArray[2]}일 ${hhmmssArray[0]}시${hhmmssArray[1]}분`;
  };

  useEffect(() => {
    const isAuth =
      getAuthTokenFromCookies("user_token") === undefined ? false : true;
    if (!isAuth) {
      alert("먼저 로그인 해주세요");
      navigate("/signIn");
    }

    dispatch(
      getRoomsActions.getRoomListPending({
        nowPage: 1,
        limit: 8,
      })
    );
  }, []);

  const { rooms, maxPage } = useAppSelector((state) => state.roomList);

  return (
    <div className={classes.root}>
      <button
        onClick={() => {
          navigate("/createRoom");
        }}
      >
        방 개설하기
      </button>
      <div className={classes.mainText}>
        <Typography variant="h5" gutterBottom>
          플레이중인 방들
        </Typography>
        <Typography variant="subtitle1" gutterBottom color="textSecondary">
          모두가 방을 나가거나, 방을 만든지 1시간이되면 삭제됩니다.
        </Typography>
        <button
          onClick={() => {
            dispatch(
              getRoomsActions.getRoomListPending({
                nowPage: selectPage,
                limit: 8,
              })
            );
          }}
        >
          방 새로고침
        </button>
      </div>
      <Paper elevation={0}>
        <div className={classes.roomList}>
          {rooms.map((room) => {
            return (
              <div key={room._id} className={classes.room}>
                <Typography className={classes.roomTitle}>
                  <div>{"방제: " + room.title}</div>
                </Typography>
                <Typography className={classes.roomCreateTime}>
                  <div>{"등록시간: " + createTime(room.createdAt)}</div>
                </Typography>
                <Typography className={classes.roomHost}>
                  {"host: " + room.host}
                </Typography>
                <Typography className={classes.roomNowPeople}>
                  {"현재인원: " + room._nowMembers.length}
                </Typography>
                <Typography className={classes.roomComment}>
                  {room.comment}
                </Typography>
                <button
                  className={classes.roomEnterBtn}
                  onClick={() => {
                    navigate(`/roomList/${room._id}`);
                  }}
                >
                  입장하기
                </button>
              </div>
            );
          })}
        </div>
      </Paper>
      <Pagination
        className={classes.pagenationNavi}
        count={maxPage}
        showFirstButton
        showLastButton
        page={selectPage}
        onChange={paginationNavigationHandler}
      />
    </div>
  );
}
