import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useNavigate, Routes, Route } from "react-router-dom";
import { Notice } from "./components/Notice";
import RoomList from "./components/RoomList";
import AppNaviAuth from "./components/AppNaviAuth";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import MyPage from "./components/MyPage";
import Event from "./components/Event";
import CreateRoom from "./components/CreateRoom";
import RoomElement from "./components/RoomElement";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appHeader: {
      backgroundColor: "#765cab",
      display: "grid",
      gridTemplateColumns: "0.4fr 0.6fr",
      gridTemplateAreas: `"appTitle appAuth"`,
    },
    appTitle: {
      gridArea: "appTitle",
      fontSize: "2rem",
      fontWeight: "bold",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    appNavi: {
      width: "100%",
      borderBottom: "1px solid #bbbbbb",
      justifyContent: "flex-end",
      alignItems: "center",
      marginRight: "30px",
    },
    appAuth: {
      gridArea: "appAuth",
      fontSize: "16px",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      marginRight: "20px",
    },
    appNaviTabs: {
      width: "50px",
    },
  })
);

function App() {
  const classes = useStyles();
  const [value, setValue] = useState(1);
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);

    switch (newValue) {
      case 0:
        navigate("/notice");
        break;
      case 1:
        navigate("/roomList");
        break;
      case 2:
        navigate("/event");
        break;

      default:
        navigate("/roomList");
        break;
    }
  };
  return (
    <>
      <div className={classes.appHeader}>
        <div className={classes.appTitle}>링플조아</div>
        <div>{process.env.REACT_APP_TEST}</div>
        <div className={classes.appAuth}>
          <AppNaviAuth />
        </div>
      </div>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        className={classes.appNavi}
      >
        <Tab label="공지사항" />
        <Tab label="플레이룸" />
        <Tab label="대회" />
      </Tabs>

      <Routes>
        <Route path="notice" element={<Notice />} />
        <Route path="roomList" element={<RoomList />} />
        <Route path="signIn" element={<SignIn />} />
        <Route path="signUp" element={<SignUp />} />
        <Route path="myPage" element={<MyPage />} />
        <Route path="event" element={<Event />} />
        <Route path="/createRoom" element={<CreateRoom />} />
        <Route path="/roomList/*" element={<RoomElement />} />
      </Routes>
    </>
  );
}

export default App;
