export const getAuthTokenFromCookies = (name: string) => {
  let matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export function deleteAuthToken(name: string, value: string) {
  document.cookie = `${name}=${value}; max-age=-1;`;
}

export function saveAuthToken(userToken: string) {
  document.cookie = `user_token=${userToken}; max-age=3599; samesite=lax;`;
}
