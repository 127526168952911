import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router";
import { getAuthTokenFromCookies, deleteAuthToken } from "../utils/auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    authBtn: {
      margin: "0 0 0 20px",
      color: "#ffffff",
    },
  })
);

export default function AppNaviAuth() {
  const classes = useStyles();
  const navigate = useNavigate();

  // 쿠키에 토큰 소지여부 판단

  const isAuth =
    getAuthTokenFromCookies("user_token") === undefined ? false : true;

  if (isAuth) {
    return (
      <>
        <div
          className={classes.authBtn}
          onClick={() => {
            navigate("/myPage");
          }}
        >
          마이페이지
        </div>
        <div
          className={classes.authBtn}
          onClick={() => {
            const tokenValue = getAuthTokenFromCookies("user_token");
            if (tokenValue === undefined) {
              alert("이미 로그아웃 되었습니다.");
              navigate("/signIn");
              return;
            }
            deleteAuthToken("user_token", tokenValue);
            navigate("/signIn");
          }}
        >
          로그아웃
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          className={classes.authBtn}
          onClick={() => {
            navigate("/signIn");
          }}
        >
          로그인
        </div>
      </>
    );
  }
}
